/**
 * This folder contains CMS things that should run both on client and server.
 *
 * For only server code:
 * @see $server/modules/cms
 */

import { Fragment, type ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import { template } from 'lodash';
import type { EmptyObject } from '$util/types';
import type { CmsContentfulComponentItem, CmsContentfulImage } from './contentful.types';

/**
 * It will always contain `dictionary`, but it can also contain
 * additional transformed CMS data from typePolicies.
 *
 * eg.
 * ```
 * {
 *    readFooterData,
 *    dictionary
 * }
 * ```
 */
export type CmsContentRecord<T = EmptyObject> = T extends EmptyObject
  ? CmsDictionaryContent
  : T & CmsDictionaryContent;

/**
 * This is the prop that what will be set by the page and on AppContext.
 *
 * @todo remove
 * @deprecated don't use the dictionary mechanism anymore
 */
export interface CmsContentProps<T = EmptyObject> {
  /**
   * @deprecated don't use the dictionary mechanism anymore
   */
  readonly cmsContent: CmsContentRecord<T>;
}

/**
 * @todo remove
 * @deprecated don't use the dictionary mechanism anymore
 */
export interface CmsDictionaryContent {
  /**
   * @deprecated don't use the dictionary mechanism anymore
   */
  readonly dictionary: CmsContentDictionary;
}

export type CmsContentDictionary = {
  components: Record<string, CmsContentfulComponentItem>;
  pageTemplate: string;
};

/**
 * Dev friendly transformed `CmsImage`
 * @see `getImageDetailsFromCmsImage()`
 */
export interface ImageDetails {
  readonly key: string;
  readonly url: string;
  readonly alt: string;
  readonly title?: string;
  readonly width: number;
  readonly height: number;
}

/**
 * Gets the CMS image data into a more friendly shape.
 */
export const getImageDetailsFromCmsImage = ({
  key,
  alt,
  title,
  img: [{ original_secure_url, width, height }],
}: CmsContentfulImage): ImageDetails => ({
  key,
  url: original_secure_url,
  alt,
  title,
  width,
  height,
});

const placeholderRegex = /{([\s\S]+?)}/g;

/**
 * Replaces extended text placeholders with corresponding vars.
 * Used with `renderText()` option of `renderRichTextContent()`.
 *
 * Conventions:
 * - placeholders in the form `{var}`
 */
export const extendCmsText = (text: string, vars: Record<string, string | number>): string =>
  template(text, { interpolate: placeholderRegex })(vars);

/**
 * Replace placeholders with ReactNodes.
 * Used with `renderText()` option of `renderRichTextContent()`.
 *
 * @note
 * If the placeholders are in RichText, the text usually is wrapped in a `<p>` tag
 * so make sure the tags in ReactNodes are allowed. (eg. no `<div> in `<p>`)
 */
export const extendCmsTextReact = (text: string, vars: Record<string, ReactNode>): JSX.Element => {
  const textNodes = reactStringReplace(text, placeholderRegex, (match: string, i) => {
    const node = vars[match] ?? `{${match}}`;
    return <Fragment key={i}>{node}</Fragment>;
  });
  // eslint-disable-next-line react/jsx-no-useless-fragment -- return one element instead of array of nodes
  return <>{textNodes}</>;
};

export const isNotEmptyContent = (text: string): boolean => text !== '' && text.toUpperCase() !== '[EMPTY]';
