import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import vars from '$util/theme/vars';
import type { IconProps } from '@/components/atoms/Icon';
import { ButtonWithContent } from '@/components/atoms/Button/components/ButtonWithContent';
import { ButtonWithIconOnly } from '@/components/atoms/Button/components/ButtonWithIconOnly';
import type { ButtonSizes } from './constants';
import { ICON_SIZE_MAP, DesignType } from './constants';

import styles from './index.module.scss';

export * from './constants';

export interface ButtonBaseProps {
  readonly size?: ButtonSizes;
  readonly designType?: DesignType;
  readonly fullWidth?: boolean;
  readonly type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  readonly disabled?: boolean;
  readonly iconId?: IconProps['id'];
  readonly iconSize?: IconProps['width'];
  readonly loading?: boolean;
  readonly noIcon?: boolean;
  readonly iconOnly?: boolean;
  readonly noShadow?: boolean;
  readonly dataE2eId?: string;
}

export interface HTMLAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly href: string;
}
export interface HTMLButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly rel?: never;
}

export type ButtonProps = ButtonBaseProps & (HTMLButtonProps | HTMLAnchorProps);

export function Button({
  size = 'M',
  type = 'button',
  fullWidth,
  designType = DesignType.PRIMARY,
  disabled,
  children,
  className,
  loading,
  iconId,
  iconSize,
  noIcon,
  noShadow,
  iconOnly,
  dataE2eId,
  ...props
}: ButtonProps): JSX.Element {
  const consolidatedClassNames = classNames(
    styles.Button,
    styles[size],
    fullWidth ? styles.fullWidth : '',
    styles[designType],
    (designType === DesignType.SECONDARY_DARK || designType === DesignType.SECONDARY_LIGHT) &&
      styles.secondary,
    loading && styles.loading,
    noShadow && styles.NoShadow,
    className
  );

  let iconColor;
  switch (designType) {
    case DesignType.CTA_LIGHT:
      iconColor = vars.quantumverse.reskinColors.purple700;
      break;
    case DesignType.CTA_DARK:
    case DesignType.PRIMARY:
      iconColor = '#FFFFFF';
      break;
    default:
      iconColor = 'currentColor';
  }

  const iconSizeToUse = iconSize || ICON_SIZE_MAP.get(designType)?.get(size);

  if (!children && iconOnly) {
    return (
      <ButtonWithIconOnly
        type={type}
        consolidatedClassNames={consolidatedClassNames}
        disabled={disabled}
        iconId={iconId}
        iconSize={iconSizeToUse}
        iconColor={iconColor}
        {...props}
      />
    );
  }
  return (
    <ButtonWithContent
      consolidatedClassNames={consolidatedClassNames}
      disabled={disabled}
      noIcon={noIcon}
      loading={loading}
      iconId={iconId}
      iconSize={iconSizeToUse}
      iconColor={iconColor}
      type={type}
      dataE2eId={dataE2eId}
      {...props}
    >
      {children}
    </ButtonWithContent>
  );
}
Button.displayName = 'Button';
