import classNames from 'classnames';

import type { ButtonProps } from '@/components/atoms/Button';
import type { IconProps } from '@/components/atoms/Icon';

import { LoadingIndicator } from '@/components/atoms/LoadingIndicator';
import { ButtonIconComponent } from '@/components/atoms/Button/components/ButtonIconComponent';

import styles from '@/components/atoms/Button/index.module.scss';

export type ButtonWithContentProps = ButtonProps & {
  readonly consolidatedClassNames: string;
  readonly iconSize: IconProps['width'];
  readonly iconColor: IconProps['color'];
};

export function ButtonWithContent({
  children,
  consolidatedClassNames,
  disabled,
  noIcon,
  loading,
  iconId = 'arrows/arrow-right',
  iconSize,
  iconColor,
  type,
  dataE2eId,
  ...props
}: ButtonWithContentProps): JSX.Element {
  if ('href' in props) {
    return (
      <a
        className={classNames(consolidatedClassNames, disabled && styles.disabled)}
        role="button"
        {...props}
        data-e2eid={dataE2eId}
        data-testid={dataE2eId}
      >
        {children && (
          <div className={styles.ButtonContainer}>
            {children}
            {!noIcon && (
              <div className={classNames(styles.ButtonIcon, 'ButtonIcon')}>
                {loading ? (
                  <LoadingIndicator className={styles.ButtonLoading} />
                ) : (
                  <ButtonIconComponent id={iconId} size={iconSize} color={iconColor} />
                )}
              </div>
            )}
          </div>
        )}
      </a>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type -- incorrect rule
      type={type}
      data-e2eid={dataE2eId}
      className={consolidatedClassNames}
      disabled={disabled}
      {...props}
    >
      {children && (
        <div className={styles.ButtonContainer}>
          {children}
          {!noIcon && (
            <div className={classNames(styles.ButtonIcon, 'ButtonIcon')}>
              {loading ? (
                <LoadingIndicator className={styles.ButtonLoading} />
              ) : (
                <ButtonIconComponent id={iconId} size={iconSize} color={iconColor} />
              )}
            </div>
          )}
        </div>
      )}
    </button>
  );
}

ButtonWithContent.displayName = 'ButtonWithContent';
